
/* Импорт палитры Flat UI Colors */
@import url('https://cdnjs.cloudflare.com/ajax/libs/flat-ui/2.3.0/css/flat-ui.css.map');



.navbar {
    --bs-navbar-nav-link-padding-x: 2.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* styles.css */

.row {
  --bs-gutter-y: 1rem;
}

.nav-link {
    cursor: pointer;
}

.navbar>.container-fluid {
    align-items: baseline
}

.navbar-nav {
    flex-direction: row;
    align-items: flex-end;
    min-width: 230px;
    justify-content: space-between;
}

.application-form {
    /* Стили для формы */
    .form-label {
      color: #2c3e50;
      display: inline-block;
      margin-bottom: 0rem;
        margin-top:0.5rem;
    }

    .form-input {
      border: none;
      border-radius: 4px;
      padding: 8px;
      color: #34495e;
    }

    .form-select {
      background-color: #ecf0f1;
      border: none;
      border-radius: 4px;
      padding: 8px;
      color: #34495e;
    }

    .form-textarea {
      border: none;
      border-radius: 4px;
      padding: 8px;
      color: #34495e;
    }

    .form-button {
      background-color: #3498db;
      border: none;
      border-radius: 4px;
      padding: 8px 16px;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }

    /* CSS стили */
    .custom-form .form-group {
      margin-bottom: 20px;
    }

    .custom-form .form-label {
      font-weight: bold;
      color: #333;
      text-align: left;
    }

    .custom-form .form-input {
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ddd;
    }

    .custom-form .form-textarea {
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ddd;
    }

    .custom-form .form-button {
      padding: 10px 20px;
      border-radius: 4px;
      background-color: #3498db;
      color: #fff;
      border: none;
    }

    .custom-form .form-button:hover {
      background-color: #2980b9;
    }

    .custom-form .form-button:active {
      background-color: #217dbb;
    }

    .container-form {
      max-width: 800px;
      margin-top: 50px;
    }

    .btn{  margin: 0.25rem 3rem 0 0; }
}


/* navbar */
.navbar-expand-lg {
  /*justify-content: end;*/
}

.navbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

/* Карточки результата */
.card {
  margin-bottom: 1em;
  margin-top: 0em;
}

.navbar-expand-lg form {
  width: 50%
}

.footer, .footer a {
    color:#6c757d;
    font-size:12px;
}

.ais-Highlight-highlighted, .ais-Snippet-highlighted {
    background-color: #ffc168;
}

.form-control .ais-SearchBox-input {
    width: calc(100% - 50px);
    border: 0
}

.form-control .ais-SearchBox-reset, .form-control .ais-SearchBox-submit {
    border: 0;
    background: #fff
}

/* Стилизация контейнера SearchBox */
.ais-SearchBox {
    position: relative;
}

/* Стилизация инпута SearchBox */
.ais-SearchBox-input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ais-SearchBox-input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}

/* Стилизация кнопки поиска */
.ais-SearchBox-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 0 0.25rem 0.25rem 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.ais-SearchBox-submit:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.ais-SearchBox-submit:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}

/* Отображение кнопки "Очистить" */
.ais-SearchBox-reset {
    display: inline-block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.ais-SearchBox-reset:hover {
    background-color: #e9ecef;
    border-color: #adb5bd;
    color: #212529;
}


/* Скрытие кнопки "очистить" */
.ais-SearchBox-reset {
    display: none;
}

/* Стилизация кнопки "Очистить" */
input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
}

/* При наведении на кнопку "Очистить" */
input[type="search"]::-webkit-search-cancel-button:hover {
    opacity: 1; /* Необязательно: добавьте прозрачность по желанию */
}

.ais-SearchBox-input::-webkit-search-cancel-button {
    display: block;
}

/* Скрытие кнопки "Поиск" */
.ais-SearchBox-submit {
    display: none;
}

.ais-RefinementList {
    color: inherit;
}

.ais-RefinementList-checkbox, .ais-RefinementList-labelText {
    vertical-align: middle;
}

.ais-RefinementList-checkbox {
    margin-right: 2px
}

h2 .ais-Highlight, h2 .ais-Highlight-nonHighlighted {
    font-size: 1.1rem;
}

h2 [class^=ais-] {
    font-size: 1.1rem;
    box-sizing: border-box;
}

[class^=ais-] {
    font-size: inherit;
    box-sizing: border-box;
}

em {
    font-style: normal;
}

.see-contacts {
    text-decoration: none;
    border-bottom: 1px dotted #0a53be;
    cursor: pointer;
}

.contacts {
    font-size:0.75rem;
}

.contacts p, .project-info p {
    padding: 0;
    margin: 0;

}

.project-info {
    font-size: 0.75rem
}

/* Extra large devices (large desktops) */
@media (max-width: 1200px) {
  /* Ваши стили для экстра-больших устройств */
}

/* Large devices (desktops) */
@media (max-width: 991.98px) {
  /* Ваши стили для больших устройств */
}

/* Medium devices (laptops) */
@media (max-width: 767.98px) {
  .navbar-expand-lg form {
    width: 100%
  }

  .add-button.btn {
    margin-top: 1em;
  }

  /* Ваши стили для средних устройств */
}

/* Small devices (tablets) */
@media (max-width: 575.98px) {
  /* Ваши стили для маленьких устройств */
}

